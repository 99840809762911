<template>
    <el-form ref="clientInfo" :rules="rules" class="card-tail w-full half-width" label-position="top" :model="clientInfo" v-loading="$waiting.is('loading')">
        <div class="p-5">
            <header class="text-header-blue text-xl font-semibold mb-3">Allmänt</header>
            <div class="flex space-x-2">
                <el-form-item class="w-full" label="Namn på företaget" prop="name">
                    <el-input v-model="clientInfo.name" />
                </el-form-item>
                <el-form-item class="w-full" label="Nummer hos Bolageriet">
                    <el-input v-model="clientInfo.clientNumber" :disabled="true" />
                </el-form-item>
                <el-form-item class="w-full" label="Organisationsnummer" prop="registrationNumber">
                    <el-input v-model="clientInfo.registrationNumber" />
                </el-form-item>
            </div>
            <div class="flex space-x-2">
                <el-form-item class="w-full" label="Status">
                    <el-select class="w-full" v-model="clientInfo.companyStatus" placeholder="--- Välj status ---">
                        <el-option v-for="status in status" :label="status.name" :value="status.value" :key="status.value" />
                    </el-select>
                </el-form-item>
                <el-form-item class="w-full" label="Företagsform">
                    <el-select class="w-full" v-model="clientInfo.companyType" placeholder="--- Välj företagsform ---">
                        <el-option v-for="type2 in companyTypes" :label="type2.name" :value="type2.value" :key="type2.value" />
                    </el-select>
                </el-form-item>
                <el-form-item class="w-full" label="Rekommendation">
                    <el-select clearable class="w-full" v-model="clientInfo.recommenderId" placeholder="--- Välj rekommendation ---">
                        <el-option v-for="recommendation in recommendationOptions" :label="recommendation.name" :value="recommendation.value" :key="recommendation.value" />
                    </el-select>
                </el-form-item>
            </div>
        </div>
        <div class="px-5 pb-5">
            <div class="flex space-x-2">
                <el-form-item class="w-full" label="Adress" prop="address">
                    <el-input v-model="clientInfo.address" />
                </el-form-item>
                <el-form-item class="w-full" label="Adress 2" prop="address2">
                    <el-input v-model="clientInfo.address2" />
                </el-form-item>
            </div>
            <div class="flex space-x-2">
                <el-form-item class="w-full" label="Ort" prop="city">
                    <el-input v-model="clientInfo.city" />
                </el-form-item>
                <el-form-item class="w-full" label="Postnummer" prop="postalCode">
                    <el-input v-model="clientInfo.postalCode" />
                </el-form-item>
                <el-form-item class="w-full" label="Land">
                    <el-select class="w-full" v-model="clientInfo.countryId" placeholder="--- Välj land ---">
                        <el-option v-for="country in countries" :label="country.name" :value="country.value" :key="country.value" />
                    </el-select>
                </el-form-item>
            </div>
            <div class="flex space-x-2">
                <el-form-item class="w-full" label="E-postadress" prop="email">
                    <el-input v-model="clientInfo.email" />
                </el-form-item>
                <el-form-item class="w-full" label="E-postadress admin" prop="emailAdmin">
                    <el-input v-model="clientInfo.emailAdmin" />
                </el-form-item>
            </div>
            <div class="flex space-x-2">
                <el-form-item class="w-full" label="Telefonnummer" prop="phone">
                    <el-input v-model="clientInfo.phone" />
                </el-form-item>
                <el-form-item class="w-full" label="Hemsida" prop="www">
                    <el-input v-model="clientInfo.www" />
                </el-form-item>
            </div>
        </div>
        <div class="px-5 pb-5">
            <header class="text-header-blue text-xl font-semibold mb-3">Provision</header>
            <div class="flex space-x-2">
                <el-form-item class="w-full" label="Provision/faktura (%)" prop="provisionInvoicePercentage">
                    <InputCurrency v-model="clientInfo.provisionInvoicePercentage" :custom-class="inputBorder" />
                </el-form-item>
                <el-form-item class="w-full" label="Provision/faktura fast" prop="provisionInvoiceFixed">
                    <InputCurrency v-model="clientInfo.provisionInvoiceFixed" :custom-class="inputBorder" />
                </el-form-item>
            </div>
            <div class="flex space-x-2">
                <el-form-item class="w-full" label="Provision/månad" prop="provisionMonthly">
                    <InputCurrency v-model="clientInfo.provisionMonthly" :custom-class="inputBorder" />
                </el-form-item>
                <el-form-item class="w-full" label="Max provision/år" prop="maxYearProvision">
                    <InputCurrency v-model="clientInfo.maxYearProvision" :custom-class="inputBorder" />
                </el-form-item>
            </div>
            <div class="flex space-x-2">
                <el-form-item class="w-full" label="Max provision/månad" prop="maxMonthProvision">
                    <InputCurrency v-model="clientInfo.maxMonthProvision" :custom-class="inputBorder" />
                </el-form-item>
                <el-form-item class="w-full" label="Betalar provision f.o.m." prop="provisionFromDate">
                    <el-date-picker type="date" style="width: 100%;" v-model="clientInfo.provisionFromDate" />
                </el-form-item>
            </div>
        </div>
        <div class="px-5 pb-5">
            <header class="text-header-blue text-xl font-semibold mb-3">Nummerserie</header>
            <div class="flex space-x-2">
                <el-form-item class="w-full" label="Faktura nummer">
                    <el-input v-model="clientInfo.invoiceNumber" />
                </el-form-item>
                <el-form-item class="w-full pt-8">
                    <el-button type="primary" @click="saveInvoiceNumber">Spara fakturanummer</el-button>
                </el-form-item>
            </div>
        </div>
        <div class="px-5 pb-5 space-y-1">
            <header class="text-header-blue text-xl font-semibold mb-3">Inställningar</header>
            <el-form-item>
                <el-checkbox v-model="clientInfo.test">Test</el-checkbox>
            </el-form-item>
            <el-form-item>
                <el-checkbox v-model="clientInfo.newBg">Visa "Nytt Bankgiro" på fakturor</el-checkbox>
            </el-form-item>
            <el-form-item>
                <el-checkbox v-model="clientInfo.privateFirmUseCard">Företagskort (enskild firma)</el-checkbox>
            </el-form-item>
            <el-form-item>
                <el-checkbox v-model="clientInfo.advanced">Avancerat läge</el-checkbox>
            </el-form-item>
            <el-form-item>
                <el-checkbox v-model="clientInfo.showBankSync">Open Banking</el-checkbox>
            </el-form-item>
            <el-form-item>
                <el-checkbox v-model="clientInfo.disableReminder">Skicka inga påminnelser</el-checkbox>
            </el-form-item>
            <el-form-item>
                <el-checkbox v-model="clientInfo.disableDebtCollection">Skicka inte till inkasso</el-checkbox>
            </el-form-item>
            <el-form-item>
                <el-checkbox v-model="clientInfo.projectsEnabled">Projekt</el-checkbox>
            </el-form-item>
            <el-form-item>
                <el-checkbox v-model="clientInfo.apiEnabled">Åtkomst till api</el-checkbox>
            </el-form-item>
            <el-form-item>
                <el-checkbox v-model="clientInfo.chatbotEnabled">Chatbot</el-checkbox>
            </el-form-item>
        </div>
        <div class="px-5 pb-5">
            <header class="text-header-blue text-xl font-semibold mb-3">Shopify</header>
            <div class="flex flex-col">
                <el-form-item label="Bokföra ordrar f.o.m." prop="shopifyStartFetchingOrderDate">
                    <el-date-picker type="date" style="width: 150px;" v-model="clientInfo.shopifyStartFetchingOrderDate" />
                </el-form-item>
            </div>
        </div>
        <el-form-item class="px-5">
            <el-button type="primary" @click="onSubmit">Spara</el-button>
            <el-button @click="goBack()">Tillbaka</el-button>
        </el-form-item>
    </el-form>
</template>
<script>
import Api from "../clients.api";
import moment from "moment";

export default {
    data() {
        return {
            clientInfo: {},
            recommendationOptions: [],
            companyTypes: [
                { name: "Aktiebolag", value: 5 },
                { name: "Enskild firma", value: 1 },
                { name: "Handelsbolag", value: 2 },
                { name: "Ekonomisk förening", value: 3 },
                { name: "Komanditbolag", value: 4 },
                { name: "Allmänt visstidsanställd", value: 6 },
                { name: "Endast fakturering", value: 7 },
            ],
            status: [
                { name: "Aktiv", value: 0 },
                { name: "Konkurs", value: 1 },
                { name: "Ej kund längre", value: 2 },
                { name: "Ej kontaktbar", value: 3 },
                { name: "Aktiv ej klar", value: 4 },
                { name: "Inaktiverad", value: 5 },
                { name: "Ombildat till AB", value: 6 },
            ],
            countries: [],
            rules: {
                name: [{ required: true, message: "Nödvändig", trigger: "blur" }],
                provisionInvoicePercentage: [{ required: true, message: "Nödvändig", trigger: "blur" }],
                provisionInvoiceFixed: [{ required: true, message: "Nödvändig", trigger: "blur" }],
                provisionMonthly: [{ required: true, message: "Nödvändig", trigger: "blur" }],
                maxYearProvision: [{ required: true, message: "Nödvändig", trigger: "blur" }],
                maxMonthProvision: [{ required: true, message: "Nödvändig", trigger: "blur" }],
                provisionFromDate: [{ required: true, message: "Nödvändig", trigger: "blur" }],
                registrationNumber: [
                    { required: true, message: "Nödvändig", trigger: "blur" },
                    { pattern: /^\d{6,8}[-]{0,1}\d{4}$/, message: "Kontrollera organisationsnummer", trigger: "blur" },
                ],
                email: [
                    { max: 40, message: "E-post får inte innehålla mer än 40 tecken", trigger: ["blur", "change"] },
                    { type: "email", message: "Vänligen ange korrekt e-postadress", trigger: ["blur", "change"] },
                ],
                emailAdmin: [{ max: 40, message: "E-post får inte innehålla mer än 40 tecken", trigger: ["blur", "change"] }],
                www: [{ max: 40, message: "Hemsida får inte innehålla mer än 40 tecken", trigger: ["blur", "change"] }],
                phone: [{ max: 20, message: "Telefonnummer får inte innehålla mer än 20 tecken", trigger: ["blur", "change"] }],
                address: [{ max: 100, message: "Adress får inte innehålla mer än 100 tecken", trigger: "blur" }],
                address2: [{ max: 100, message: "Adress får inte innehålla mer än 100 tecken", trigger: "blur" }],
                city: [{ max: 100, message: "Ort får inte innehålla mer än 100 tecken", trigger: "blur" }],
                postalCode: [{ max: 10, message: "Postnummer får inte innehålla mer än 10 tecken", trigger: "blur" }],
            },
        };
    },

    watch: {
        $route: function() {
            this.getDetails();
        },
    },

    components: {
        InputCurrency: () => import(/* webpackChunkName: "InputCurrency" */ "@/components/InputCurrency.vue"),
    },

    async created() {
        this.$waiting.start("loading");
        this.countries = await Api.getCountryOptions();
        this.recommendationOptions = await Api.getRecommendationOptions();
        this.clientInfo = await Api.getDetails(this.$route.params.clientId);
        this.$waiting.end("loading");
        document.title = "Klienter - " + this.clientInfo.name;
    },

    methods: {
        async getDetails() {
            this.$waiting.start("loading");
            this.clientInfo = await Api.getDetails(this.$route.params.clientId);
            this.$waiting.end("loading");
            document.title = "Klienter - " + this.clientInfo.name;
        },

        async saveInvoiceNumber() {
            const confirm = await this.$dialog.title("Fakturanummer").confirm("Är du säker på att du vill fortsätta?");
            if (!confirm) return;
            this.$waiting.start("loading");
            await Api.updateInvoiceNumber(this.$route.params.clientId, this.clientInfo.invoiceNumber);
            this.$waiting.end("loading");
        },

        async onSubmit() {
            await this.validateForm();
            this.$waiting.start("loading");
            if (this.clientInfo.provisionFromDate != null) this.clientInfo.provisionFromDate = moment(this.clientInfo.provisionFromDate).format("YYYY-MM-DD HH:mm:ss");
            if (this.clientInfo.shopifyStartFetchingOrderDate != null)
                this.clientInfo.shopifyStartFetchingOrderDate = moment(this.clientInfo.shopifyStartFetchingOrderDate).format("YYYY-MM-DD HH:mm:ss");
            await Api.updateClientDetails({ clientId: this.$route.params.clientId, clientDetails: this.clientInfo });
            this.$waiting.end("loading");
            this.$notify.success({ title: "Utfört" });
        },

        async validateForm() {
            await new Promise((resolve, reject) => {
                this.$refs.clientInfo.validate(valid => {
                    if (valid) {
                        resolve();
                    } else {
                        reject(this.scrollUp());
                    }
                });
            });
        },

        scrollUp() {
            window.scrollTo({ top: window.innerHeight / 3, behavior: "smooth" });
        },

        goBack() {
            this.$router.push({ path: "/clientList" });
        },
    },

    computed: {
        inputBorder() {
            return "w-full text-sm py-1 px-2 rounded border border-solid input-border";
        },
    },
};
</script>
<style scoped>
@media screen and (min-width: 1383px) {
    .half-width {
        width: 50%;
    }
}
</style>
